<!-- 班次管理 -->
<template>
  <div class="classesManage">
    <div class="searchWrapper">
      <el-form ref="form" :inline="true" :model="form">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.Shift_name')">
              <el-autocomplete
                class="inline-input"
                v-model="form.scheduleName"
                :fetch-suggestions="querySearch"
                placeholder="请输入班次名称"
                value-key="scheduleName"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Belonging_operator')">
              <el-select
                v-model.trim="form.operationId"
                filterable
                size="15"
                @change="getOperationInfo"
              >
                <el-option label="全部" value></el-option>
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in tenantList"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.state')">
              <el-select v-model.trim="form.type">
                <el-option label="全部" value=""></el-option>
                <el-option label="有效" value="0"></el-option>
                <el-option label="失效" value="1"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              :loading="loading"
              v-if="$route.meta.authority.button.query"
              @click="
                page = 1;
                queryDate();
              "
              >{{ $t("button.search") }}</el-button
            >
          </div>
        </div>
        <div class="col_box_boder"></div>
        <div class="col_box h44">
          <div class="col_left">
            <el-button type="primary" icon="el-icon-plus" @click="add()"
              >添加班次
            </el-button>
          </div>
          <div class="col_right mbd4"></div>
        </div>
      </el-form>
    </div>
    <div class="tableWrapper bgFFF paddingB10">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column
          align="center"
          v-for="item in tableCols"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :formatter="item.formatter"
        >
        </el-table-column>
        <el-table-column
          :label="$t('list.operation')"
          align="center"
          width="90"
        >
          <template slot-scope="scope">
            <el-dropdown @command="handleCommand($event, scope.row)">
              <el-button style="padding: 0" type="text" size="small">
                操作 <i class="el-icon-arrow-down"
              /></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a"> 查看 </el-dropdown-item>
                <el-dropdown-item command="b" v-if="scope.row.state != 1">
                  编辑
                </el-dropdown-item>
                <el-dropdown-item command="c" v-if="scope.row.state != 1">
                  删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
export default {
  name: "classes",
  components: {},
  data() {
    return {
      tenantList: [],
      form: {
        scheduleName: "",
        type: "0",
        operationId: "",
      },
      tableData: [],
      tableCols: [
        {
          prop: "scheduleName",
          label: this.$t("list.Shift_name"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "",
        },
        {
          prop: "onTime",
          label: this.$t("list.work_hours"),
          width: "",
        },
        {
          prop: "offTime",
          label: this.$t("list.off_work_time"),
          width: "",
        },
        {
          prop: "state",
          label: this.$t("list.state"),
          width: "",
          formatter: (row) => {
            let obj = {
              0: i18n.t("list.effective"),
              1: i18n.t("list.lose_efficacy"),
              2: i18n.t("list.effective"),
            };
            return obj[row.state];
          },
        },
      ],
      loading: false,
      restaurants: [],
      page: 1,
      pageSize: 15,
      total: 0,
    };
  },
  // 方法集合
  methods: {
    // 所属运营商选择
    getOperationInfo(val) {
      this.form.operationId = val;
    },
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    handleCommand(event, row) {
      switch (true) {
        case event == "a":
          this.detail(row);
          break;
        case event == "b":
          this.edit(row);
          break;
        case event == "c":
          this.delete(row);
          break;
        default:
          break;
      }
    },
    delete(row) {
      this.$confirm("此操作将删除班次，是否继续？", this.$t("pop_up.Tips"), {
        confirmButtonText: this.$t("pop_up.Determine"),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // /acb/2.0/pdaSchedule/del
          // /acb/2.0/pdaSchedule/invalid/{scheduleId}
          this.$axios
            .post("/acb/2.0/pdaSchedule/del/" + row.scheduleId)
            .then((res) => {
              if (res.state == 0) {
                if (res.value == 15) {
                  this.$confirm(res.desc, this.$t("pop_up.Tips"), {
                    confirmButtonText: this.$t("pop_up.Determine"),
                    cancelButtonText: "取消",
                    type: "info ",
                  }).then(() => {
                    this.$axios
                      .post("/acb/2.0/pdaSchedule/invalid/" + row.scheduleId)
                      .then((res) => {
                        this.$alert(res.desc, this.$t("pop_up.Tips"), {
                          confirmButtonText: this.$t("pop_up.Determine"),
                        }).then(() => {
                          this.queryDate();
                        });
                      });
                  });
                } else {
                  this.$alert(res.desc, this.$t("pop_up.Tips"), {
                    confirmButtonText: this.$t("pop_up.Determine"),
                  }).then(() => {
                    this.queryDate();
                  });
                }
              } else {
                this.$alert(res.desc, this.$t("pop_up.Tips"), {
                  confirmButtonText: this.$t("pop_up.Determine"),
                }).then(() => {
                  this.queryDate();
                });
              }
            })
            .catch(() => {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleCurrentChange(v) {
      this.page = v;
      this.queryDate();
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.scheduleName
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    queryDate(data) {
      // acb/2.0/pdaSchedule/page
      this.loading = true;
      this.$axios
        .get("/acb/2.0/pdaSchedule/page", {
          data: {
            scheduleName: data || this.form.scheduleName,
            state: this.form.type,
            operationId: this.form.operationId,
            page: this.page,
            size: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.value.list;
          //          if (this.tableData.length === 0) {
          //            if (this.page == 1) {
          //              this.page = 1;
          //              this.queryDate();
          //            } else {
          //              this.page--;
          //              this.queryDate();
          //            }
          //          }
          this.total = res.value.total * 1;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    allPdaSchedule() {
      this.$axios
        .get("/acb/2.0/pdaSchedule/page", {
          data: {
            scheduleName: "",
            state: this.form.type,
            page: 0,
            size: 0,
          },
        })
        .then((res) => {
          this.restaurants = res.value.list;
        });
    },
    handleSelect(v) {
      this.page = 1;
      this.queryDate(v.scheduleName);
    },
    detail(data) {
      this.$router.push({
        path: "/classesManageDetail",
        query: data,
      });
    },
    edit(data) {
      this.$router.push({
        path: "/classesAddEdit",
        query: data,
      });
    },
    add() {
      this.$router.push({
        path: "/classesAddEdit",
      });
    },
  },
  created() {
    this.getTenantList();
  },
  mounted() {
    this.queryDate();
    this.allPdaSchedule();
  },
  activated() {
    this.queryDate();
  },
};
</script>
<style lang="stylus" scoped>
// @import url(); 引入公共css类
.searchWrapper {
  overflow: hidden;
  // margin-bottom: 20px;
}

.classesManage {
  .form_content {
    .el-form {
      overflow: hidden;

      .addBtn {
        float: right;
      }
    }
  }
}
</style>
